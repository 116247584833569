<template>
  <div class="genomemoset-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label="$l.get('genomemoset-form.name-field', 'capitalize')"
          placeholder="gms000"
          :danger="isNameInvalid"
          :danger-text="$l.get('errors.invalid-genomemoset-name', 'capitalize')"
          v-model="genomemoset.name"
        />
        <br />
        <div>
          <vs-textarea
            :label="$l.get('genomemoset-form.about-pt-br-text-field')"
            v-model="genomemoset.aboutPtBrText"
          />
        </div>
        <div>
          <vs-textarea
            :label="$l.get('genomemoset-form.about-en-us-text-field')"
            v-model="genomemoset.aboutEnUsText"
          />
        </div>
        <div>
          <vs-textarea
            :label="$l.get('genomemoset-form.about-es-es-text-field')"
            v-model="genomemoset.aboutEsEsText"
          />
        </div>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>
            {{ $l.get("dictionary.variables") }}
          </h3>
        </div>
        <vs-tabs v-model="variablesTabIndex">
          <vs-tab :label="$l.get('dictionary.all')">
            <vs-table :data="genomemoset.variables">
              <template slot="thead">
                <vs-th>{{ $l.get("dictionary.variable") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.wejoy-diagnosis") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.wejoy-diagnosis-area") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.edit") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.remove") }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="idx" v-for="(tr, idx) in data">
                  <vs-td :data="tr.name">{{ tr.name }}</vs-td>
                  <vs-td :data="tr.diagnosisTitle">{{
                    tr.diagnosisTitle
                  }}</vs-td>
                  <vs-td :data="tr.areaTitle">{{ tr.areaTitle }}</vs-td>
                  <vs-td>
                    <vs-button
                      color="primary"
                      type="border"
                      icon="edit"
                      @click="onEditVariableClick(idx, tr)"
                    ></vs-button>
                  </vs-td>
                  <vs-td>
                    <vs-button
                      color="primary"
                      type="border"
                      icon="delete"
                      @click="onRemoveVariableClick(idx)"
                    ></vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>
          <vs-tab :label="variableFormTab">
            <br />
            <label for="" class="vs-input--label">{{
              $l.get("dictionary.wejoy-diagnosis")
            }}</label>
            <!-- <p>{{ $l.get("dictionary.wejoy-diagnosis") }}</p> -->
            <div v-if="variableActive.diagnosisTitle">
              <vs-row>
                <vs-col vs-w="10">
                  <h2>{{ variableActive.diagnosisTitle }}</h2>
                  <label for="" class="vs-input--label">
                    {{ variableActive.diagnosis }}
                  </label>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-w="2"
                  vs-justify="right"
                  vs-align="right"
                >
                  <vs-button
                    color="warning"
                    type="border"
                    icon="delete"
                    @click="onClearVariableDiagnosisClick"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </div>
            <div v-else>
              <vue-simple-suggest
                @select="onDiagnosisSelect"
                :list="diagnostics"
                display-attribute="title"
                value-attribute="id"
                :filter-by-query="true"
              ></vue-simple-suggest>

              <div
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
                style="height: 19px;"
              >
                <span
                  class="span-text-validation"
                  v-if="isVariableDiagnosisInvalid"
                >
                  {{ $l.get("errors.input-required") }}
                </span>
              </div>
            </div>
            <div v-if="areas" style="margin-top: 25px">
              <label for="" class="vs-input--label">{{
                $l.get("dictionary.wejoy-diagnosis-area")
              }}</label>
              <div v-if="variableActive.areaTitle">
                <vs-row>
                  <vs-col vs-w="10">
                    <h2>{{ variableActive.areaTitle }}</h2>
                    <label for="" class="vs-input--label">
                      {{ variableActive.area }}
                    </label>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-w="2"
                    vs-justify="right"
                    vs-align="right"
                  >
                    <vs-button
                      color="warning"
                      type="border"
                      icon="delete"
                      @click="onClearVariableDiagnosisClick"
                    ></vs-button>
                  </vs-col>
                </vs-row>
              </div>
              <div v-else>
                <vue-simple-suggest
                  @select="onDiagnosisAreaSelect"
                  :list="areas"
                  display-attribute="title"
                  value-attribute="id"
                  :filter-by-query="true"
                ></vue-simple-suggest>

                <div
                  class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
                  style="height: 19px;"
                >
                  <span
                    class="span-text-validation"
                    v-if="isVariableDiagnosisInvalid"
                  >
                    {{ $l.get("errors.input-required") }}
                  </span>
                </div>
              </div>
            </div>
            <vs-input
              :label="$l.get('genomemoset-form.name-field', 'capitalize')"
              :danger="isVariableNameInvalid"
              :danger-text="$l.get('errors.input-required', 'capitalize')"
              v-model="variableActive.name"
            />
            <vs-divider />
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-save"
              style="margin: auto; width: 150px !important"
              @click="onSaveVariableClick()"
            ></vs-button>
          </vs-tab>
        </vs-tabs>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("genomemoset-form.criteria-text-field") }}</h3>
        </div>
        <div>
          <br />
          <vs-textarea
            :label="$l.get('genomemoset-form.criteria-text-field')"
            v-model="genomemoset.criteriaText"
            v-on:keyup="onCriteriaUpdate"
          />
          <vs-alert
            color="danger"
            :active="true"
            v-for="(text, index) in criteriaErrors"
            :key="index"
            >{{ text }}</vs-alert
          >
        </div>
      </vs-card>
      <!-- <div>
          <vs-textarea
            :label="$l.get('genomemoset-form.criteria-field')"
            v-model="genomemoset.criteriaText"
          />
      </div>-->
      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">{{
        $l.get("dictionary.submit-new")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
const GATES = ["and", "or", "not", "atleast"];
const RE_GT = /^([Ii]|[Rr][Ss])\d+\([A-Z]+(;[A-Z]+)?\)$/i;
const RE_GS = /^[Gg][Ss]\d+$/i;
const RE_GMS = /^[Gg][Mm][Ss]\d+$/i;
const RE_BMC = /^[Bb][Mm][Cc]\(\w+,[+-]?[01],[+-]?[01]\)$/i;
const RE_NUMBER = /^\d+$/i;

import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";

export default {
  name: "genomemoset-form",
  props: ["genomemoset", "onSubmit", "diagnostics"],
  components: { VueSimpleSuggest },
  data() {
    return {
      isNameInvalid: false,
      criteriaErrors: [],
      isVariableNameInvalid: false,
      isVariableDiagnosisInvalid: false,
      variablesTabIndex: 0,
      variableEditIdx: null,
      variableActive: null,
      areas: null,
      gateways: {
        research: null,
        session: null
      }
    };
  },
  beforeMount() {
    this.gateways.session = this.$injector.get("SessionGateway");
    this.gateways.research = this.$injector.get("ResearchGateway");
    this.clearVariable();
  },
  mounted() {},
  watch: {
    variablesTabIndex: function() {
      if (this.variablesTabIndex == 0) {
        this.variableEditIdx = null;
        this.clearVariable();
      }
    }
  },
  computed: {
    variableFormTab() {
      if (this.variableEditIdx == null) {
        return this.$l.get("dictionary.new");
      } else {
        return this.$l.get("dictionary.edit");
      }
    }
  },
  methods: {
    onFormSubmit() {
      this.isNameInvalid = !this.genomemoset.name.match(RE_GMS);
      this.genomemoset.criteriaJson = JSON.stringify(
        this.criteriaValidate(this.genomemoset.criteriaText)
      );
      if (!this.isNameInvalid && this.criteriaErrors.length == 0) {
        this.genomemoset.aboutPtBrHtml = this.genomemoset.aboutPtBrText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.genomemoset.aboutEnUsHtml = this.genomemoset.aboutEnUsText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.genomemoset.aboutEsEsHtml = this.genomemoset.aboutEsEsText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.onSubmit();
      }
    },
    criteriaValidate(criteria) {
      criteria = criteria.replace(/\s/g, "");
      if (
        criteria.match(RE_GT) ||
        criteria.match(RE_GS) ||
        criteria.match(RE_GMS)
      ) {
        return {
          node: criteria,
          text: criteria,
          children: []
        };
      }
      if (criteria.match(RE_BMC)) {
        const criteriaVarName = criteria.split(",")[0].split("(")[1];
        const valence = criteria.split(",")[1];
        const arousal = criteria.split(",")[2].split(")")[0];
        var updatedCriteria = null;
        for (let varInfo of this.genomemoset.variables) {
          if (criteriaVarName == varInfo.name) {
            updatedCriteria = `bmc(${varInfo.diagnosis},${varInfo.area},${valence},${arousal})`;
            break;
          }
        }
        if (!updatedCriteria) {
          this.criteriaErrors.push(`VARIABLE "${criteriaVarName}" NOT FOUND`);
        }
        return {
          node: updatedCriteria,
          text: criteria,
          children: []
        };
      }
      let currentChild = "";
      let children = [];
      let gate = "";
      let deep = 0;
      let isClosed = false;
      let unexpectedTokens = "";
      for (let i in criteria) {
        let token = criteria[i];
        if ([" ", "\t", "\n"].indexOf(token) > -1) {
          // continue
        } else if (isClosed) {
          unexpectedTokens += token;
        } else if (token == "(") {
          if (deep > 0) {
            currentChild += token;
          }
          deep++;
        } else if (token == ")") {
          deep--;
          if (deep > 0) {
            currentChild += token;
          } else {
            isClosed = true;
          }
        } else if (deep == 0) {
          gate += token;
        } else if (deep > 0) {
          if (deep == 1 && token == ",") {
            children.push(currentChild);
            currentChild = "";
          } else {
            currentChild += token;
          }
        }
      }
      if (currentChild) {
        children.push(currentChild);
      }
      if (deep != 0) {
        this.criteriaErrors.push(`MISSING ")"`);
      }
      if (unexpectedTokens) {
        this.criteriaErrors.push(`INVALID EXPRESSION: ${unexpectedTokens}`);
      }
      let isValidAtLeast = false;
      if (GATES.indexOf(gate) < 0) {
        this.criteriaErrors.push(`INVALID EXPRESSION: ${gate}`);
      } else {
        if (children.length == 0) {
          this.criteriaErrors.push(`INVALID GATE PARAMETERS: ${gate}`);
        } else if (gate == "atleast") {
          const numberStr = children[0];
          if (!numberStr.match(RE_NUMBER)) {
            this.criteriaErrors.push(`Expected number and found ${numberStr}`);
          } else {
            isValidAtLeast = true;
            const number = parseInt(numberStr);
            if (number >= children.length) {
              this.criteriaErrors.push(
                `Expected at least ${numberStr} parameters and found ${children.length -
                  1}`
              );
            }
          }
        } else if (gate == "and" && children.length < 2) {
          this.criteriaErrors.push(
            `Expected at least 2 parameters and found ${children.length}`
          );
        } else if (gate == "or" && children.length < 2) {
          this.criteriaErrors.push(
            `Expected at least 2 parameters and found ${children.length}`
          );
        }
      }
      let json_children = [];
      for (let i in children) {
        const child = children[i];
        if (isValidAtLeast && i == 0) {
          json_children.push({
            node: child,
            text: child,
            children: []
          });
        } else {
          json_children.push(this.criteriaValidate(child));
        }
      }
      return {
        text: criteria,
        node: gate,
        children: json_children
      };
    },
    onCriteriaUpdate() {
      this.criteriaErrors = [];
      let criteria_json = this.criteriaValidate(this.genomemoset.criteriaText);
    },
    onDiagnosisSelect(e) {
      this.variableActive.diagnosis = e.id;
      this.variableActive.diagnosisTitle = e.title;
      const session = this.gateways.session.get();
      const _ = this;
      this.gateways.research
        .listDiagnosticsAreas(session.tokens, e.id)
        .then(areas => {
          _.areas = areas;
        });
    },
    onDiagnosisAreaSelect(e) {
      this.variableActive.area = e.id;
      this.variableActive.areaTitle = e.title;
    },
    clearVariable() {
      this.areas = null;
      this.variableActive = {
        name: "",
        diagnosis: "",
        diagnosisTitle: "",
        area: "",
        areaTitle: ""
      };
    },
    onSaveVariableClick() {
      this.isVariableNameInvalid = this.variableActive.name.length < 1;
      this.isVariableDiagnosisInvalid = !this.variableActive.diagnosis;
      if (this.isVariableNameInvalid || this.isVariableDiagnosisInvalid) {
        return;
      }
      if (this.variableEditIdx == null) {
        this.genomemoset.variables.push(this.variableActive);
      } else {
        this.genomemoset.variables[this.variableEditIdx] = this.variableActive;
      }
      this.clearVariable();
      this.variablesTabIndex = 0;
      this.onCriteriaUpdate();
      this.$forceUpdate();
    },
    onEditVariableClick(idx, variable) {
      this.variableEditIdx = idx;
      this.variableActive = JSON.parse(JSON.stringify(variable));
      this.variablesTabIndex = 1;
    },
    onClearVariableDiagnosisClick() {
      this.variableActive.diagnosis = null;
      this.variableActive.diagnosisTitle = null;
      this.variableActive.area = null;
      this.variableActive.areaTitle = null;
      this.areas = null;
    },
    onRemoveVariableClick(idx) {
      this.genomemoset.variables.splice(idx, 1);
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
@import "@/application/assets/scss/vuexy/extraComponents/autocomplete.scss";

.genomemoset-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.genomemoset-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.genomemoset-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.genomemoset-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.genomemoset-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

.genomemoset-form .con-vs-tabs {
  overflow-x: hidden !important;
  overflow-y: visible !important;
}

.genomemoset-form .con-vs-tabs .con-slot-tabs {
  overflow-x: hidden !important;
  overflow-y: visible !important;
}
// .genomemoset-form .vs-input {
//   margin: 15px;
// }
</style>
